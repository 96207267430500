<template>
  <div>
    <div class="product-content">
      <div class="content">
        汇修帮是一款为大家提供汽车服务的手机平台，这款平台里面就汇聚了大量不同类型的汽配商品的信息，还能在这为你提供了最新而全面的汽车的型号，能便捷的车辆进行维修的服务提供给用户。在这就能拥有了各类的品牌的汽车零件，这样就支持了汽车的各种不同问题带来的服务，这样就能满足了不同汽车的使用需求。现在你还能看见了更多的服务，就能让你自己去下载去体验的。
      </div>
      <el-divider class="el-divider-active"></el-divider>
      <div class="characteristic">汇修帮特色</div>
      <div class="plcontent">
        <p>
          1、最好的线上销售服务提供给你，快速在线提供最全面的汽车型号给你选择;
        </p>
        <p>2、修车更加的省钱，不用担心修理厂的一些配件比较贵了;</p>
        <p>
          3、自己直接在这里买一些配件，然后让师傅帮忙去进行维修一下就可以啦。
        </p>
        <div class="box">
          <div>
            <img
              src="http://system.yxht.net/yaan_13.png"
              alt=""
              class="image"
            />
          </div>
          <div>
            <img
              src="http://system.yxht.net/yaan_12.png"
              alt=""
              class="image"
            />
          </div>
        </div>
      </div>
      <el-divider class="el-divider-active"></el-divider>
      <div class="characteristic">汇修帮亮点</div>
      <div class="plcontent">
        <p>
          1、便捷的掌上汽车零配件线上销售综合服务平台，主要提供各类品牌的汽车零件销售服务;
        </p>
        <p>
          2、通过app就能快速搜索到各型号的汽车配件，让车主和汽修厂能更好的进货，找到各类稀缺的资源;
        </p>
        <p>
          3、让用户能轻松找到各类配件，同时商城有着专属优惠，各类配件专科偶购买，随时查看购买和运输记录等。
        </p>
        <div class="box">
          <div>
            <img
              src="http://system.yxht.net/yaan_15.png"
              alt=""
              class="image"
            />
          </div>
          <div>
            <img
              src="http://system.yxht.net/yaan_14.png"
              alt=""
              class="image"
            />
          </div>
        </div>
      </div>
      <el-divider class="el-divider-active"></el-divider>
      <div class="characteristic">汇修帮优点</div>
      <div class="plcontent">
        <p>1. 发布招聘需求，随时随地物色汽修专业人才</p>
        <p>
          2.
          人工+智能对接采购需求，直接有效匹配优质汽配经销商，节约汽修人员的时间与采购成本
        </p>
        <p>3. 可发送文字、图片、文字+图片的需求信息</p>
        <p>4. 查看所有有效的报价列表信息</p>
        <p>5. 实时数据更新，可自由开启或结束采购需求</p>
        <p>6. 采购商品可直接支付货款</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  watch: {},
  computed: {},
};
</script>

<style scoped lang='scss'>
.product-content {
  width: 1240px;
  margin: 20px auto;
  background-color: #fff;
}
.content {
  padding-top: 20px;
  color: #14679f;
  font-size: 14px;
  line-height: 30px;
}
.characteristic {
  color: #333;
  font-size: 18px;
  padding: 0 0 20px 0;
  font-weight: 700;
}
.plcontent {
  color: #14679f;
  font-size: 14px;
  line-height: 30px;
  text-indent: 25px;
}
.box {
  display: flex;
  .image {
    width: 300px;
    margin-top: 30px;
  }
}
</style>